
.section-hero {
    h1, h1 strong {
        position: relative;
        font-size: var(--fs-4xl);
        margin-bottom: .5rem;
        &::after {
            position: absolute;
            content: '';
            width: 4rem;
            height: .5rem;
            background: var(--dark-green-1);
            left: 0;
            bottom: -.5rem;
        }
    }
}

.section-blocks {
    display: grid;
    gap: var(--flow-spacing, 1rem);
    
    h2, h2 strong {
        position: relative;
        margin-bottom: .8rem;
        &::after {
            position: absolute;
            content: '';
            width: 4rem;
            border-bottom: .5rem dotted var(--dark-violet-1);
            left: 0;
            bottom: -.8rem;
        }
    }
}


[class^="section-block"] {

    h1, h2, h3, h4, h5, h6  {
        line-height: var(--lh-sm, 1.2);
        margin-top: calc(var(--flow-spacing) * 0.5);

    }
    h1, h1 strong {
        font-size: var(--fs-4xl);
    }
    h2, h2 strong {
        font-size: var(--fs-3xl);
    }

    h3, h3 strong {
        font-size: var(--fs-2xl);
    }
    h4, h4 strong {
        font-size: var(--fs-lg);
    }
    p:not(.block-callout p) {
        font-size: var(--fs-base);
        line-height: var(--lh-base, 1.5);
    }
    p.small {
        font-size: var(--fs-xs);
    }
    p.large {
        font-size: var(--fs-lg);
    }

    ul li, ol li {
        font-size: var(--fs-base);
        line-height: var(--lh-base, 1.5);
        margin-left: 1.4rem;
    }

    ol {
        margin-left: 1.4rem;
        li {
            list-style: decimal;
            &::marker {
                font-weight: var(--fw-secondary-bold);
                color: var(--dark-green-1);
            }
            ol {
                
                li {
                    list-style: lower-latin;
                }
            }
        }
    }
	
    ol, ul {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-6);

        ol, ul {
            margin-top: var(--spacing-6);
        }
	}

    ul li {
        display: inline-block;
        list-style: none;
		align-items: flex-start;
        position: relative;
        padding-left: 1.5rem;


        &:before {
            --size: var(--fs-base);
            position: absolute;
            content: '';
            display: inline-block;
            min-width: var(--size);
            height: var(--size);
            background: var(--dark-green-1);
            border-radius: var(--radius-full);
            left: 0rem;
            top: 0.3rem;

        }
    }

    p a, ul a , ol a {
        text-decoration: underline;
        text-underline-offset: var(--link-underline-offset);
        text-decoration-thickness: var(--link-decoration-thickness);
    
        &:hover, &:focus {
            background: var(--bg-hover);
            border-radius: var(--radius-full);
        }
    }

}



.breadcrumb {
    font-size:var(--text-sm);
    padding-bottom: var(--spacing-8);
    
    ol {
        display: flex;
        align-items: center;

        li {
            display: inline-flex;
            align-items: center;
    
            &:not(:last-child):after {
                content: '>';
                margin: 0 0.5rem;
            }
            &:last-child {
                font-weight: var(--fw-primary-bold);
            }


            a {
                font-size: var(--text-sm);
                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 0.2rem;
                }
                svg {
                    width: 1rem;
                    height: auto;
                }
                
            }
        }
    }
}




