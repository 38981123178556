

.block-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--spacing-4)
}



.button-icon {
    display: flex;
    svg {
        fill: var(--text-primary);
        height: 2rem;
        width: 2rem;
    }
    &:hover, &:focus {
        background: var(--bg-secondary);
    }
}
.button {
    display: flex;
    justify-self: flex-start;
    line-height: var(--lh-sm);
    font-weight: var(--fw-primary-bold);
    

    .button-wrapper {
        border-radius: var(--radius-full);
        display: flex;
        align-items: center;
        gap: var(--spacing-2);
    }
}

.button-secondary {
    color: var(--dark-red-1);
    .button-wrapper {
        padding: var(--spacing-2) var(--spacing-3);
        transition: var(--transition-fast);
        background: var(--light-white);
        
        svg {
            width: 1.5rem;
            height: 1.5rem;
            flex-shrink: 0;
        }
    }

    &:hover, &:focus {
        .button-wrapper {
            background: var(--bg-hover);
            transition: var(--transition-fast);
            border-radius: var(--radius-full);
        }
    }
}

.button-primary {
    /* buttons */
    --button-primary-bg:  var(--dark-red-1);
    --button-primary-text: var(--light-white);
    --button-primary-border-radius: ;
    --border-size: 0rem;
    --border-clr-primary: transparent;
    --translate-y: -6.5px;
    position: relative;
    color: var(--button-primary-text);
    transition: var(--transition-fast);
    margin-top: calc(var(--translate-y) * -1);

    
    &:hover, &:focus {
        .button-wrapper {
            transform: translatey(0);
            transition: var(--transition-fast);
        }
    }
    
    .button-wrapper {
        background: var(--button-primary-bg);
        padding: var(--spacing-4) var(--spacing-6);
        transform: translatey(var(--translate-y));
        overflow: hidden;
        transition: var(--transition-fast);
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--dark-red-2);
        border-radius: var(--radius-full);
        overflow: hidden;
    }
    

}