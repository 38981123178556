


article.card {
    --color-bg: var(--light-green-1);
    --color-bg-tag: var(--light-green-3);
    --translate-y: calc(var(--spacing-2) * -1);
    --paddingx: var(--spacing-6);  


    &.card-events {
        --color-bg: var(--light-green-1);
    }
    
    &.card-news {
        --color-bg: var(--light-violet-1);
        --color-bg-tag: var(--light-violet-2);
    }
    &.card-ressourcies {
        --color-bg: var(--light-yellow-1);
        --color-bg-tag: var(--light-yellow-2);
    }


    
    position: relative;
    display: flex;
    margin-top: var(--paddingx);

    .card__content {
        position: relative;
        display: flex;
        flex-direction: column;
        background: var(--light-white);
        border-radius: var(--radius-base);
        width: 100%;

        transform: translate(var(--translate-y), var(--translate-y));
        overflow: hidden;
        transition: var(--transition-fast);
        z-index: 1;

        &:hover, &:focus {
            transform: translate(0, 0);
        }


        header {
            padding: var(--spacing-6) var(--paddingx) var(--spacing-4);
            background: var(--color-bg-tag);
            font-family: var(--ff-secondary);
            font-weight: var(--fw-primary-bold);
            font-size: var(--fs-lg);
            text-transform: capitalize;

        }

        .card__content-body {
            padding: var(--spacing-3) var(--paddingx);
            margin-bottom: var(--spacing-4);
            h3 {
                font-family: var(--ff-primary);
                font-weight: var(--fw-primary-bold);
                font-size: var(--fs-xl);
                line-height: var(--lh-sm);
                margin-bottom: var(--spacing-4);
            }

        }

        footer {
            display: grid;
            padding: var(--spacing-3) var(--spacing-1);
            margin-top: auto;
            .tag {
                padding: var(--spacing-1) var(--paddingx);
            }
            .button {
                justify-self: flex-end;
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-bg);
        border-radius: var(--radius-base);
        overflow: hidden;
        z-index: 0;
    }
    
}