@font-face {
    font-family: 'Alpino';
    src: url('../fonts/Alpino-Black.woff2') format('woff2'),
        url('../fonts/Alpino-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'AtkinsonHyperlegible';
    src: url('../fonts/AtkinsonHyperlegible-Bold.woff2') format('woff2'),
        url('../fonts/AtkinsonHyperlegible-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'AtkinsonHyperlegible';
    src: url('../fonts/AtkinsonHyperlegible-Regular.woff2') format('woff2'),
        url('../fonts/AtkinsonHyperlegible-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@layer base {
	:root {
		/* //////////////////////////////////////////////////////
			PRIMITIVES
		//////////////////////////////////////////////////////// */ 

		/* Colors HSL */
		--clr-dark-green-1: 168, 66.8%, 39%;
		--clr-dark-black: 0, 0%, 0%;
		--clr-dark-red-1: 0.37, 65.9%, 45.6%;
		--clr-dark-red-2: 0.53, 89%, 24.9%;
		--clr-dark-violet-1: 300 26.8% 50.2%;

		/* Colors HSL light */
		--clr-light-green-1: 151, 32.1%, 68.8%;
		--clr-light-green-2: 151, 29.9%, 73.1%;
		--clr-light-green-3: 152, 43.2%, 82.7%;
		--clr-light-green-4: 168, 41%, 48%;
		--clr-light-violet-1: 304, 14%, 43.3%;
		--clr-light-violet-2: 300, 12.4%, 82.5%;
		--clr-light-yellow-1: 50.8, 100%, 50%;
		--clr-light-yellow-2: 45.7, 100%, 82.7%;
		--clr-light-red-1: 0, 100%, 95%;
		--clr-light-gray-1: 48, 18.5%, 94.7%;
		
		
		/* Colors Vars */
		--dark-green-1: hsl(var(--clr-dark-green-1));
		--dark-black: hsl(var(--clr-dark-black));
		--dark-red-1: hsl(var(--clr-dark-red-1));
		--dark-red-2: hsl(var(--clr-dark-red-2));
		--dark-violet-1: hsl(var(--clr-dark-violet-1));

		--light-green-1: hsl(var(--clr-light-green-1));
		--light-green-2: hsl(var(--clr-light-green-2));
		--light-green-3: hsl(var(--clr-light-green-3));
		--light-green-4: hsl(var(--clr-light-green-4));
		--light-violet-1: hsl(var(--clr-light-violet-1));
		--light-violet-2: hsl(var(--clr-light-violet-2));
		--light-yellow-1: hsl(var(--clr-light-yellow-1));
		--light-yellow-2: hsl(var(--clr-light-yellow-2));
		--light-gray-1: hsl(var(--clr-light-gray-1));
		--light-white: hsl(0, 0%, 100%);
		--light-red-1: hsl(var(--clr-light-red-1));
		
		/* Typographie */
		--ff-primary: 'AtkinsonHyperlegible';
		--fw-primary-regular: 400;
		--fw-primary-bold: 700;

		--ff-secondary: 'Alpino';
		--fw-secondary-bold: 900;

		--lh-base: 1.5;
		--lh-sm: 1.35;


	


		/* transition */
		--transition-bezier: cubic-bezier(.23,1,.32,1);
		--transition-duration-base: 1s;
		--transition-element: all;
		--transition: var(--transition-element) var(--transition-duration-base, .3s) var(--transition-bezier, ease-in-out);
		--transition-fast: var(--transition-element) .4s var(--transition-bezier, ease-in-out);

		
		/* Links underline */
		--link-underline-offset: 0.2rem;
		--link-decoration-thickness: 0.1rem;


		/* other */
		--shadow-1: 0 0 1rem rgba(0,0,0,0.1);

		
		/* Border */
		--border-size: 3px;
		--border-clr-primary: var(--dark-red-1);
		--border-clr-secondary: var(--dark-black);
		--border-primary: var(--border-size) solid var(--border-clr-primary, black);


		--radius-base: 1.25rem;
		--radius-lg: 2.5rem;
		--radius-xl: 3.75rem;
		--radius-full: 9999px;
		--z-index-forground-1: 30;     


		


		/* //////////////////////////////////////////////////////
			Theme
		//////////////////////////////////////////////////////// */

		/* text */
		--text-primary:  var(--dark-black);

		/* backgrounds */
		--bg-default: var(--light-gray-1, WhiteSmoke);
		--bg-primary: var(--light-white, white);
		--bg-secondary: var(--light-green-2);
		--bg-theme: var(--bg-default);

		--bg-accent: var(--light-yellow-1);
		--bg-hover: var(--light-red-1);

	
		
		
		



		/* ////////////////////////////////////////////////////// 
			Responsive
		////////////////////////////////////////////////////// */
		/* content max width */
		--content-max-width: 80ch;      

		[data-container="base"]{
			--content-max-width: 80ch;
		}
		[data-container="lg"]{
			--content-max-width: 130ch;
		}
		
		/* COMPOSITION SIZES */
		--flow-spacing: 2em;
		--gutter-base: clamp(1rem, 3.5vw, 3.5rem);
		--gutter-lg: clamp(4rem, 10vw, 10rem);
		--py-section-base:  clamp(2rem, 0.0916rem + 6.1069vw, 4rem);


		/* spacing */
		--spacing-1: clamp(0.2rem, 0.25rem + 0.02vw, 0.6rem);
		--spacing-2: clamp(0.4rem, 0.5rem + 0.04vw, 1.2rem);
		--spacing-3: clamp(0.6rem, 0.75rem + 0.06vw, 1.8rem);
		--spacing-4: clamp(0.8rem, 1rem + 0.08vw, 2.4rem);
		--spacing-5: clamp(1rem, 1.25rem + 0.1vw, 3rem);
		--spacing-6: clamp(1.2rem, 1.5rem + 0.12vw, 3.6rem);
		--spacing-8: clamp(1.6rem, 2rem + 0.16vw, 4.8rem);
		--spacing-10: clamp(2rem, 2.5rem + 0.2vw, 6rem);
		--spacing-12: clamp(2.4rem, 3rem + 0.24vw, 7.2rem);
		--spacing-16: clamp(3.2rem, 4rem + 0.32vw, 9.6rem);
		--spacing-20: clamp(4rem, 5rem + 0.4vw, 12rem);
		--spacing-24: clamp(4.8rem, 6rem + 0.48vw, 14.4rem);
		--spacing-32: clamp(6.4rem, 8rem + 0.64vw, 19.2rem);
		--spacing-40: clamp(8rem, 10rem + 0.8vw, 24rem);
		--spacing-48: clamp(9.6rem, 12rem + 0.96vw, 28.8rem);
		--spacing-56: clamp(11.2rem, 14rem + 1.12vw, 33.6rem);
		--spacing-64: clamp(12.8rem, 16rem + 1.28vw, 38.4rem);
		
		/* Font sizes */
		--fs-xs: clamp(0.75rem, 0.6307rem + 0.3817vw, 0.875rem);
		--fs-sm: clamp(0.875rem, 0.7557rem + 0.3817vw, 1rem);
		--fs-base:clamp(1rem, 0.8569rem + 0.458vw, 1.05rem);
		--fs-lg: clamp(1.125rem, 0.8865rem + 0.7634vw, 1.375rem);
		--fs-xl: clamp(1.25rem, 1.06rem + 1.5vw, 1.75rem);
		--fs-2xl: clamp(1.5rem, 1.21rem + 2.03vw, 1.875rem);
		--fs-3xl: clamp(1.875rem, 1.37rem + 3.15vw, 2.25rem);
		--fs-4xl: clamp(2.25rem, 1.54rem + 4.74vw, 3rem);
		--fs-5xl: clamp(3rem, 1.7rem + 6.66vw, 3.75rem);
		--fs-6xl: clamp(3.75rem, 1.85rem + 9.99vw, 4.5rem);
		--fs-7xl: clamp(4.5rem, 2rem + 14.99vw, 6rem);
		--fs-8xl: clamp(6rem, 2.2rem + 22.48vw, 8rem);
		--fs-9xl: clamp(8rem, 2.4rem + 33.72vw, 10rem);

	}
}