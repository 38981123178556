.pagination {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: var(--spacing-4, 1rem);

    padding-top: var(--spacing-8, 2rem);
    padding-bottom: var(--spacing-8, 2rem);


    .selector-page {
        display: flex;
        flex-direction: column;
        form {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: var(--spacing-2);

            label {
                font-weight: var(--fw-primary-bold);
            }
    
            select {
                padding: var(--spacing-2, .4rem) var(--spacing-4, .8rem);
                border: var(--border-primary);
                border-radius: var(--radius-full);
                background: transparent;
                color: var(--dark-red-1);
                font-weight: var(--fw-primary-bold);
                font-size: var(--fs-base);
            }
        }
    }
    



    .btn-icon {
        --size: 2.5rem;
        color: var(--dark-red-1);
        border-radius: var(--radius-full);
        border: var(--border-primary);
        svg {
            width: var(--size);
            height: var(--size);
        }

        &[aria-disabled="true"] {
            opacity: .5;
            cursor: not-allowed;
        }

        &:hover:not([aria-disabled="true"]), &:focus {
            background: var(--dark-red-1);
            color: var(--light-white);
        }
    }




   
}