
.site-header{
	z-index: var(--z-index-forground-1, 20);
	padding-top: var(--spacing-1);
	padding-bottom: var(--spacing-6);
	transition: var(--transition-fast);


	.logo-site{
		max-width: 10rem;
		transition: var(--transition-fast);

		svg {
			width: 100%;
		}
	}
	
	.preheader-wrapper {
		font-size: var(--fs-sm);
		padding-top: var(--spacing-2);
		padding-bottom: var(--spacing-3);
		a.menu-link {
			&:hover,
			&:focus {
				text-decoration: underline;
				text-decoration-thickness: var(--link-decoration-thickness);
				text-underline-offset: var(--link-underline-offset);
				background: white;
				border-radius: var(--radius-full);
			}
			padding: var(--spacing-2) var(--spacing-2);
		}
		
		/* ON DESKTOP */
		@media screen and (min-width: 375px) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: var(--spacing-4);
			padding-bottom: var(--spacing-4);
		}
		
		/* ON MOBILE */
		@media screen and (max-width: 768px) {	
			

		}
	}

	.header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;	
		--content-max-width: calc(130ch + 2rem);
		width: min(100% - var(--gutter-base), var(--content-max-width));
		
		margin-inline: auto;
		padding-inline: var(--spacing-4);
		padding-top: var(--spacing-2);
		padding-bottom: var(--spacing-2);
		
		background: var(--bg-primary);
		border-radius: var(--radius-full);
	}
	
	



	

	/* ON DESKTOP */
	@media screen and (min-width: 1024px) {
		


		.header-wrapper nav {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		
			
			ol{
				display: flex;
				
				li.first-level a.menu-link,
				li.has-submenu .label {
					white-space: nowrap;
					padding: var(--spacing-4) var(--spacing-4);
					border-radius: var(--radius-full);
					&:hover,
					&:focus,
					&[aria-current="page"] {
						background-color: var(--light-yellow-1);
					}
				}

				li.has-submenu {
					position: relative;
					display: flex;
					
					&:hover,
					&:focus-within {
						cursor: pointer;
						ul.submenu {
							display: block;
						}
					}
					
					ul.submenu {
						display: none;
						position: absolute;
						top: 100%;
						left: 50%;
						overflow: hidden;
						
						padding-top: var(--spacing-2);
						padding-bottom: var(--spacing-2);
						padding-inline: var(--spacing-2);

						border-radius: var(--radius-base);
						background: var(--bg-accent);
						box-shadow: var(--shadow-1);
						z-index: var(--z-index-forground-1);
						transform: translate(-50%);

						
						li a {
							white-space: nowrap;
							padding-inline: var(--spacing-2);
							border-radius: var(--radius-base);

							&:before {
								display: inline-block;
								content: '';
								width: 0.3rem;
								height: 0.3rem;
								background-color: var(--text-primary);
								border-radius: 50%;
								margin-right: 0.5rem;
							}

							&:hover,
							&:focus {
								background: var(--bg-primary);
							}
						}

					}
				}
			}

		}
	}
	
	/* ON MOBILE */
	@media screen and (max-width: 1024px) {	
		--top-header: 4.7rem;
		.menu-icon {
			background: var(--bg-accent);
			border-radius: var(--radius-full);
		}

		.preheader-wrapper {
			gap: var(--spacing-4);
		}
		
		&[data-status="folded"] {
			.header-wrapper nav {
				display: none;
				visibility: hidden;
				opacity: 0;
				
			}
			
		}
	
	
		&[data-status="unfolded"] {
			.menu-icon {
				position: fixed;
				top: calc(var(--top-header) + var(--spacing-3));
				right: calc(var(--spacing-8));
				z-index: 9999;
			}
			.preheader-wrapper {
				z-index: 2;
			}
			.header-wrapper {
				&::before {
					content: '';
					position: absolute;
					top: var(--top-header);
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(75, 70, 69, 0.95); /* Gris transparent */
					z-index: 1; /* Assurez-vous qu'il est derrière le contenu */
				}
			}

			.header-wrapper nav {
				position: absolute;
				display: flex;
				flex-direction: column;
				top: var(--top-header);
				width: calc(100% - var(--spacing-10));
				bottom: 0;
				height: 100vh;


				overflow-y: scroll;
				animation-name: open;
				animation-fill-mode: forwards;
				animation-duration: 400ms;
				animation-timing-function: ease-in-out;

				padding-top: var(--spacing-24);
				padding-bottom: var(--spacing-16);
				padding-left: var(--spacing-10);
				background: var(--bg-primary);
				border-radius: var(--radius-base) 0 0 var(--radius-base);
				z-index: 2; /* Assurez-vous qu'il est derrière le contenu */

			


				ol {
					display: flex;
					flex-direction: column;
					gap: var(--spacing-8);
					padding-bottom: var(--spacing-16);

					/* Titre */
					li.first-level a.menu-link,
					li.has-submenu .label {
						svg {
							display: none;
						}
						font-size: var(--fs-xl);
						color: var(--text-secondary);
						font-weight: var(--fw-primary-bold);
					}

					/* Sous menu */
					li.has-submenu {
						gap: var(--spacing-4);
						display: flex;
						flex-direction: column;
						ul.submenu {
							display: flex;
							flex-direction: column;
							gap: var(--spacing-6);
							a {
								font-size: var(--fs-base);
								&:before {
									display: inline-block;
									content: '';
									width: 0.3rem;
									height: 0.3rem;
									background-color: var(--light-yellow-1);
									border-radius: 50%;
									margin-right: 0.5rem;
								}
							}
						}
					}


					

					li {
						animation-name: slide-in;
						animation-fill-mode: forwards;
						animation-duration: var(--transition-duration, 300ms);
						opacity: 0;

						
						
						--base-delay: 0.1s;
						&:nth-child(1) {
							animation-delay: calc(var(--base-delay) * 1);
						}
						&:nth-child(2) {
							animation-delay: calc(var(--base-delay) * 2);
						}
						&:nth-child(3) {
							animation-delay: calc(var(--base-delay) * 3);
						}
						&:nth-child(4) {
							animation-delay: calc(var(--base-delay) * 4);
						}
						&:nth-child(5) {
							animation-delay: calc(var(--base-delay) * 5);
						}
						&:nth-child(6) {
							animation-delay: calc(var(--base-delay) * 6);
						}
						&:nth-child(7) {
							animation-delay: calc(var(--base-delay) * 7);
						}
					}
				}
			
			}
		}
	}
}


@keyframes slide-in {
    0% {
		transform: translateX(2rem);
		opacity: 0;
	}
    100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes open {
	0% {
		left: 100%;

	}
	100% {
		left: var(--spacing-10);

	}
}