html {
    font-family: var(--ff-primary, sans-serif);
    font-weight: var(--fw-primary-regular);
    color: var(--text-primary, black);
    font-size: var(--fs-base);
    background: var(--bg-default);
    scroll-behavior: smooth;
}

h1, h2, h3{
    font-family: var(--ff-secondary, sans-serif);
    font-weight: var(--fw-secondary-bold);
}

html main {
  
}

img[data-lazy-src]{
    will-change:contents
}

a.underline {
    position: relative;
    text-decoration: underline;
    text-underline-offset: var(--link-underline-offset);
    text-decoration-thickness: var(--link-decoration-thickness);

    &:hover, &:focus {
        background: var(--bg-hover);
        border-radius: var(--radius-base);
    }
}


[data-theme] {
    color: var(--text-primary, black);
    fill: var(--text-primary, black);
    background-color: var(--bg-theme);
}




.grid-3-col {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}


.container-1col {
    display: grid;
    grid-template-columns:
        minmax(var(--gutter-base, 1rem), 1fr ) 
        [content-start] min(100% - (var(--gutter-base, 1rem) * 2), var(--content-max-width, 850px)) [content-end] 
        minmax(var(--gutter-base, 1rem), 1fr);
    & > * {
        grid-column: content;
    }
}



.section-content {
    display: flex;
    gap: var(--gutter-base);
    padding-bottom: var(--py-section-base);
  

    .section-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--gutter-base);
        
        @media (min-width: 768px) {
            flex-direction: row;
        }
        .section-content-body {
            max-width: var(--content-max-width);
        }
    }

}