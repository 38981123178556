.section-block-callout {
    padding-inline: var(--gutter-base);
    
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        padding-inline: var(--gutter-base);
        gap: var(--spacing-8);

        

        & > *:nth-child(odd) {
            order: 2;
        }
        & > *:nth-child(even) {
            order: 1;
        }

    @media (min-width: 768px) {
        grid-template-columns: repeat(12, 1fr);

        & > *:nth-child(odd) {
            grid-column: span 8;
            order: initial;
        }
        & > *:nth-child(even) {
            order: initial;
            grid-column: span 4;
        }
    }



    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: var(--spacing-2);

        .button.button-secondary {
            .button-wrapper {
                background: var(--light-white);
                padding: var(--spacing-4) var(--spacing-6);
            }
            &:hover, &:focus {
                .button-wrapper {
                    background: var(--bg-hover);
                    transition: var(--transition-fast);
                }
            }
        }

    }


        figure {
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }   
}