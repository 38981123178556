.section-landing-hero {
    padding-inline: var(--gutter-base);
    padding-top: var(--spacing-8);
    padding-bottom: var(--py-section-base);
    .wrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: var(--spacing-10);
        padding-inline: var(--gutter-base);
        padding-top: var(--spacing-8);
        .block-quote {
            margin-top: var(--spacing-6);
            margin-left: 0;
        }

        @media (min-width: 1024px) {
            padding-top: var(--spacing-8);
            flex-direction: row;
            gap: 0;

            /* image */
			& > :first-child {
                align-self: flex-end;
			}

            /* content */
            & > :last-child {
                max-width: 40%;
                padding-left: var(--spacing-10);
                padding-bottom: var(--spacing-10);
                .block-quote {
                    margin-top: var(--spacing-16);
                }
            }

           
        }


    }
}