.section-block-actus {
    padding-inline: var(--gutter-base, 1rem);
    
    .section-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacing-16);
        margin-inline: auto;
        container-type: inline-size;
        

        @media (min-width: 1024px) {
            grid-template-columns: repeat(4, 1fr);

            & > *:nth-child(odd) {
                grid-column: span 1;
            }
            & > *:nth-child(even) {
                grid-column: span 3;
            }
        }
        
        .cards {
            display: grid;
            grid-template-columns: 1fr;
            gap: var(--spacing-8);
    
            & > * {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--spacing-4);
                width: 100%;
                .card {
                    flex-grow: 1;
                    flex-shrink: 0;
                    width: 100%;
                }
            }
            @media (min-width: 1024px) {
                grid-template-columns: 1fr 1fr;
            }
            @container (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }




}