form.form-filter {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);  
    margin-left: auto;
    margin-right: auto;
    width: min(100%, 60rem);


    fieldset {
      


        legend {
            font-size: var(--fs-lg);
            grid-row: legend;
        }
        .field-group {
            display: flex;
            margin-top: var(--spacing-6);
            gap: var(--spacing-8);
            flex-wrap: wrap;

        }
    
        .field label{
            display: flex;
            gap: var(--spacing-2);
            align-items: center;
            cursor: pointer;
    
        }
        input[type="checkbox"] {
            appearance: none;
            width: 1.5rem;
            height: 1.5rem;
            border: 2px solid var(--text-primary);
            border-radius: 4px;
            position: relative;
            cursor: pointer;
            flex-shrink: 0;
        }
    
        input[type="checkbox"]:checked {
            background-color: var(--bg-default);
        }
    
        input[type="checkbox"]:checked::after {
            content: '✔︎';
            color: var(--text-primary);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1rem;
        }
    }

    footer {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacing-4);

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }

    }
}