.block-quote {
    --size: 3rem;
    position: relative;
    display: grid;
    font-weight: var(--fw-primary-bold);
    font-size: var(--fs-lg);
    line-height: var(--lh-sm, 1.2);
    gap: var(--spacing-4);
    margin-left: var(--gutter-base);
    margin-top: calc(var(--size) * 1.3 + var(--flow-spacing));
    
    @media (min-width: 768px) {
        --size: 3.5rem;
        margin-left: calc(var(--size) * 1);
        margin-top: calc(var(--size) * 1.3);
    }

    &::before {
        position: absolute;
        content: url('/assets/imgs/quote.svg');
        display: block;
        width: var(--size);
        height: var(--size);
    
        left: calc(var(--size) * -0.5);
        top: calc(var(--size) * -1.1);

        @media (min-width: 768px) {
            --size: 3.5rem;
            left: calc(var(--size) * -1);
            
        }
    }

    footer {
        display: flex;
        justify-self: flex-start;
        align-items: center;

        background: var(--bg-accent);
        font-weight: var(--fw-primary-regular);
        border-radius: var(--radius-full);
        padding: var(--spacing-2) var(--spacing-2);
        max-width: 30ch;

        figure {
            max-width: 4.2rem;
            min-width: 4.2rem;
        }
        .author-info {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-1);
            padding-inline: var(--spacing-4);

            .author-name {
                font-size: var(--fs-base);
                font-weight: var(--fw-primary-bold);
        
            }
            .author-description {
                font-size: var(--fs-xs);
                line-height: var(--lh-sm, 1.2);
            
            }
        }
    }
}