.list-item-metas {
    ul {
        display: flex;
        gap: var(--spacing-2);
        li {
            display: flex;
            margin-left: 0;
            padding-left: 0;
            align-items: center;
            gap: var(--spacing-2);
            &:before { 
                display: none;
            }
        }
    }
}