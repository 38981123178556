
.block-image.xl .wrapper, 
.block-image.md .wrapper, 
.block-image.default .wrapper, 
{
    border-radius: var(--radius-base);
    overflow: hidden;

    

}

.block-image.xl .wrapper {
    max-width: 50rem;
}
.block-image.md .wrapper {
    max-width: 35rem;
}
.block-image.sm .wrapper {
    max-width: 16rem;
}
.block-image.xs .wrapper {
    max-width: 4rem;
}
.block-image.custom .wrapper {
	max-width: 100%;
	@media screen and (min-width: 1024px) {
		max-width: var(--max-width, 100%);
	}
}
.block-image.custom.custom-fix .wrapper {
	max-width: var(--max-width, 100%);
}


