.py-section-base {
    padding-top: var(--py-section-base, 5rem);
    padding-bottom: var(--py-section-base, 5rem);
}

.flow > * + * {
    margin-top: var(--flow-spacing, 1rem);
}
.flow-lg > * + * {
    margin-top: calc(var(--flow-spacing, 1rem) * 2);
}

.max-w-container {
    max-width: var(--content-max-width);
    margin-inline: auto;
}
.max-w-half-container {
    max-width: calc(var(--content-max-width)/2)
}

.flex-col-distribute {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.border-t-primary {
    border-top: var(--border-primary);
}

p.small {
    font-size: var(--fs-xs);
}

.block-scroll {
    overflow: hidden;
}

/* backgrounds */
.bg-grid {
    opacity: 0.8;
    background-image:  
        linear-gradient(#CCCCCC 1px, transparent 1px), 
        linear-gradient(to right, #CCCCCC 1px, var(--bg-default) 1px);
    background-size: 32px 32px;
    background-position: bottom;
}
.bg-grid + .bg-grid {
    background-position: top;
}

.bg-green {
    background: var(--light-green-2);
}
.bg-green-dark {
    background: var(--light-green-4);
}
.bg-active {
    background: var(--bg-accent);
}
.bg-default {
    background: var(--bg-default);
}
.bg-primary {
    background: var(--bg-primary);
}


/* radius */
.radius-xl {
    border-radius: var(--radius-xl);
}
.radius-lg {
    border-radius: var(--radius-lg);
}
.radius-base {
    border-radius: var(--radius-base);
}
