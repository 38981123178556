.menu-icon {
    --bar-width: 2rem;
    --bar-space: .7rem;
    --bar-clr: var(--text-primary);
	position: relative;
	width: calc(var(--bar-width) * 2);
	height: calc(var(--bar-width) * 2);
	cursor: pointer;

	
	div {
		margin: auto;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: var(--bar-width, 22px);
		height: 1.5rem;
	}
	span {
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		background-color: var(--bar-clr);
		border-radius: 1px;
		transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
		transform-origin: left center;

        &:nth-child(1) {
			top: 0;
		}
        &:nth-child(2) {
			top: var(--bar-space);
		}
        &:nth-child(3) {
            top: calc(var(--bar-space) * 2);
		}
		
	}
	&.active,
	.menu-icon__cheeckbox:checked + div {
		span {
            &:nth-child(1) {
                transform: rotate(45deg);
                top: calc(var(--bar-space) * -1/2 + .31rem);
                left: .35rem
            }
            &:nth-child(2) {
                width: 0%;
                opacity: 0;
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
                top: calc(var(--bar-space) * 2 );
                left: .35rem
            }
		}
	}

	&.active:hover span:first-of-type,
	&.active:hover span:last-of-type,
	&:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
	&:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
		width: var(--bar-width, 22px);
	}

	&:hover {
		@media (min-width: 1280px) {
			span:first-of-type {
				width: var(--bar-width, 22px);
			}

			span:last-of-type {
				width: calc(var(--bar-width, 22px) * 0.65);
			}
		}
	}
}