.block-file {
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-base);
    background-color: var(--bg-secondary);
    padding: var(--spacing-8);
    gap: var(--spacing-8);
    align-items: flex-start;


    &.with-image {
        @media (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            figure {
                max-width: 50%;
            }

            .block-file__content {
                flex-direction: column;

            }
        }
    }

    .block-file__content {
        display: flex;
        flex-direction: column;  
        gap: var(--flow-spacing);
        align-items: flex-start;
        width: 100%;
        @media (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
        }

        .block-file__header {
            .block-file__title {
                    font-weight: var(--fw-primary-bold);
                    font-size: var(--fs-lg);
                }
        }
    }


}