.section-block-label {
    padding-inline: var(--gutter-base);
    container-type: inline-size;
    
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacing-16);

        @media (min-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: 768px) {
            .content-primary {
                position: relative;
            }
            .content-primary:after {
                display: block;
                position: absolute;
                top: 0;
                right: calc(-1 * var(--spacing-16)/2);
                content: '';
                width: 1px;
                height: 100%;
                background-color: var(--border-clr-secondary);
            }
        }
        
        .content-secondary {
            display: flex;
            flex-direction: column-reverse;
            gap: var(--flow-spacing);
    
    
            @media (min-width: 1024px) {
                flex-direction: row;
            }
            @container (min-width: 600px) {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }


}