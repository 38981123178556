.section-toc {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-8) var(--spacing-8);
    border-radius: var(--radius-lg);
    background: var(--bg-primary);
    min-width: 30ch;
    max-width: 300ch;
    align-self: flex-start;

    @media screen and (min-width: 768px) {
        position: sticky;
        top: var(--spacing-8);
    }
    .heading {
        font-size: var(--fs-lg);
    }
    ol {
        padding-left: var(--spacing-4);
        gap: var(--spacing-2);

        li {
            a {
                display: flex;
                padding: var(--spacing-2) var(--spacing-4);
                border-radius: var(--radius-full);
                margin-left: calc(var(--spacing-4) * -1);
                &:hover, &:focus, &[aria-selected="true"] {
                    background: var(--bg-accent);
                }
            }
            ol {
                padding-left: var(--spacing-4);
            }
        }
    }

}