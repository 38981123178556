.site-footer {
    background: var(--bg-primary);
    border-radius: var(--radius-xl) var(--radius-xl) 0 0;
    padding-top: var(--spacing-8);
    
    .site-footer__infos {
        display: grid;
        grid-template-columns: 1fr;
        padding-inline: var(--gutter-base);
        gap: var(--spacing-8);

       
        @media screen and (min-width: 480px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr 1fr;

            & > *:last-child {
                grid-column: 1 / -1;
            }
        }
        
        @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: 1fr;
            & > *, & > *:last-child {
                grid-column: span 4;
            }
        }

        .heading {
            font-size: var(--fs-2xl);
            font-family: var(--ff-secondary);
            margin-bottom: var(--spacing-4);
        }

        .contact {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .button {
                margin-top: var(--spacing-8);
                justify-self: flex-start;
            }
        }
        
        .follow .social-links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-2);
            margin-left: calc(-1 * var(--spacing-3));
            
            a {
                display: flex;
                align-items: center;
                gap: var(--spacing-3);
                padding: var(--spacing-2) var(--spacing-3);
                border-radius: var(--radius-full);


                &:hover, &:focus {
                    background: var(--bg-hover);
                    transition: var(--transition-fast);

                }
            }
        }
        .partenaires .logos {
            display: flex;
            flex-wrap: wrap;
            gap: var(--spacing-6);
            
            figure {
                width: clamp(6rem, 10%, 10rem);
                max-height: 6rem;
                
                img {
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        
    }



    nav[aria-label="Menu secondaire"] > *:first-child {
            display: flex;
            justify-content: center;
            border-top: var(--border-primary);
            padding-top: var(--spacing-8);
            padding-bottom: var(--spacing-8);

    }

}