.block-table {
    max-width: 100%;
    overflow-x: scroll;

    table {
        max-width: 100%;
        border-collapse: collapse;
        background: var(--bg-primary);
        border-collapse: collapse;
        border-radius: var(--radius-base);

        tr {
            border-bottom: 1px solid #BCBCBC;
            height: 4rem;
        }
        tbody tr:first-child {
            border-bottom: none;
        }

        th {
            text-align: left;
            text-transform: uppercase;
            font-weight: var(--fw-primary-regular);
        }

        th, td {
            padding: var(--spacing-4);
        }

        caption {
            padding-top: 1rem;
            caption-side: bottom;
            text-align: left;
            font-size: var(--fs-sm);
        }
    }
}