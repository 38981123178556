.section-block-plan {
    ol {
        display: grid;
        gap: var(--flow-spacing);
        margin-left: 0;

        li {
            display: grid;
            grid-template-columns: 1fr;
            gap: var(--flow-spacing);
            margin-left: 0;
            container-type: inline-size;

            @media (min-width: 1024px) {
                grid-template-columns: 1fr 2fr;
            }


            .line-heading {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: var(--spacing-4);
                padding: var(--spacing-8);
                min-height: 15rem;



                &:before {
                    --size: calc(var(--fs-2xl) * 1.5);
                    display: block;
                    top: var(--spacing-3);
                    left: var(--spacing-3);
                    position: absolute;
                    content: attr(data-number);
                    background: var(--dark-violet-1);
                    border-radius: var(--radius-full);
                    width: var(--size);
                    height: var(--size);
                    text-align: center;

                    line-height: 1.3;

                    color: var(--light-white);
                    font-family: var(--ff-secondary);
                    font-weight: var(--fw-secondary-bold);
                    font-size: var(--fs-2xl);
                }

                &:after {
                    position: absolute;
                    content: url('data:image/svg+xml;utf8,<svg width="25" height="45" viewBox="0 0 25 45" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.5668 19.8143C24.9477 21.3385 24.9477 23.6615 23.5668 25.1857L7.46432 42.9589C5.0073 45.6709 0.499998 43.9327 0.499998 40.2732L0.499999 4.72677C0.5 1.06731 5.0073 -0.670854 7.46432 2.0411L23.5668 19.8143Z" fill="%2321A68C"/></svg>');
                    display: flex;
                    justify-content: center;
                    
                    /* Modifications pour mobile */
                    bottom: -2.4rem;
                    left: calc(50% - 1.25rem);
                    transform: translateX(-50%);
                    transform: rotate(90deg);
                    
                    @media (min-width: 1024px) {
                        /* Reset des propriétés mobile */
                        bottom: auto;
                        left: auto;
                        
                        
                        /* Positionnement desktop */
                        align-items: center;
                        right: -1.5rem;
                        top: 0;
                        height: 100%;
                        transform: rotate(0deg);
                    }
                }
            }

            .line-content {
                display: grid;
                grid-template-columns: 1fr;
                gap: var(--flow-spacing);
                padding: var(--flow-spacing);


                @media (min-width: 1024px) {
                    grid-template-columns: 1fr 1fr;
                }
                @container (min-width: 768px) {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}
