article.carte-contenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-white);
    .carte-contenu__title {
        font-family: var(--ff-primary);
        font-size: var(--fs-base);
        text-align: center;
        margin-top: var(--spacing-4);
        margin-bottom: var(--spacing-4);
    }
    .button {
        justify-self: center;
        text-align: right;
        text-decoration: none;
    }
}