@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";




@import "composition.css";
@import "utilities.css";


@import "modules/block-image.css";
@import "modules/block-video.css";
@import "modules/block-buttons.css";
@import "modules/block-quote.css";
@import "modules/block-file.css";
@import "modules/carte-contenu.css";
@import "modules/block-table.css";
@import "modules/toc.css";
@import "modules/block-callout.css";




@import "sections/section-general.css";
@import "sections/section-hero.css";
@import "sections/section-block-landing-hero.css";
@import "sections/section-block-label.css";
@import "sections/section-block-plan.css";
@import "sections/section-block-callout.css";
@import "sections/section-block-actus.css";

/* plugin Navigations */
@import "../../composer/plugins/cirses-navigations/assets/css/sections/site-header.css";
@import "../../composer/plugins/cirses-navigations/assets/css/sections/footer.css";
@import "../../composer/plugins/cirses-navigations/assets/css/modules/menu-icon.css";
@import "../../composer/plugins/cirses-navigations/assets/css/modules/menu-link.css";

/* plugin Card list */
@import "../../composer/plugins/cirses-card-list/assets/css/list-item-metas.css";
@import "../../composer/plugins/cirses-card-list/assets/css/cards.css";
@import "../../composer/plugins/cirses-card-list/assets/css/list-item.css";
@import "../../composer/plugins/cirses-card-list/assets/css/pagination.css";
@import "../../composer/plugins/cirses-card-list/assets/css/form-filter.css";



@import "config.css";





